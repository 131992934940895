import { createEmbedScript } from './pulse-create-embed-script.js';

export const loadPulse = (): void => {
  createEmbedScript(
    window,
    document,
    'pulse',
    'script',
    '//cdn.aftenposten.no/js/pulse/pulse-20241205.min.js',
  );
};
